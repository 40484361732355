<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="100px">
            <el-form-item prop="messageType" label="消息类型：">
                <el-select v-model="searchForm.messageType" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in messageTypeOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="messageStatus" label="查看状态：">
                <el-select v-model="searchForm.messageStatus" placeholder="请选择" clearable class="w100i">
                    <el-option label="未读" value="0"></el-option>
                    <el-option label="已读" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="messageData" label="消息时间：">
                <el-date-picker class="w100i" v-model="messageData" value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange" clearable start-placeholder="开始时间"
                                end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="messageContent" label="输入搜索：">
                <el-input v-model.trim="searchForm.messageContent" maxlength="50" placeholder="请输入消息内容（50字内）"
                          clearable @keyup.enter.native="search(1)"></el-input>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item label-width="0" class="f_r">
                <el-button type="danger" size="small" icon="el-icon-delete" v-show="hasPermission('personal:message:del')" @click="del()">批量删除
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="searchForm.messageClassification" size="small" @tab-click="reset()">
                <el-tab-pane :label="'全部'+'('+countInfo.total+')'" name="-1"></el-tab-pane>
                <el-tab-pane :label="'档案消息'+'('+countInfo.archives+')'" name="0"></el-tab-pane>
                <el-tab-pane :label="'藏品消息'+'('+countInfo.collection+')'" name="1"></el-tab-pane>
                <el-tab-pane :label="'数字资源消息'+'('+countInfo.resources+')'" name="2"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    @selection-change="selectionChangeHandle"
                    height="calc(100vh - 401px)"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" :reserve-selection='true' fixed/>
                <el-table-column prop="borrowCodeNumber" label="消息内容" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="text_left">
                            <span class="dot" v-if="scope.row.messageStatus == '未读'"></span>
                            <span v-html="scope.row.messageContent"></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="messageType" label="消息类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("messageType", scope.row.messageType ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="messageStatus" label="查看状态" show-overflow-tooltip></el-table-column>
                <el-table-column prop="messageData" label="消息时间" sortable show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('personal:message:view')"
                                   @click="view(scope.row)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('personal:message:del')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="查看消息" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible"
                   :before-close="handleClose">
            <el-descriptions :column="1" class="p_l2" v-loading="infoLoading">
                <el-descriptions-item label="消息类型">{{$dictUtils.getDictLabel("messageType", info.messageType ,'')}}</el-descriptions-item>
                <el-descriptions-item label="消息状态">{{info.messageStatus == 0 ? '未读':'已读'}}</el-descriptions-item>
                <el-descriptions-item label="消息时间">{{info.messageData}}</el-descriptions-item>
                <el-descriptions-item label="消息内容">
                    <span v-html="info.messageContent"></span>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    messageClassification: '-1',
                    messageContent: '',
                    messageData: '',
                    messageEndData: '',
                    messageStatus: '',
                    messageType: '',
                    current: 1,
                    size: 10,
                },
                messageTypeOpt: this.$dictUtils.getDictList('messageType'),
                messageData: [],
                loading: false,
                dataList: [],
                total: 0,
                dataListSelections: [],
                countInfo: {
                    total: 0,
                    archives: 0,
                    collection: 0,
                    resources: 0,
                },
                // 查看
                visible: false,
                infoLoading: false,
                info: {},
            }
        },
        mounted() {
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.getCount()
                if(this.searchForm.messageClassification == '1') {
                    this.messageTypeOpt = this.$dictUtils.getDictList('messageType').filter(val => val.value != '2')
                } else {
                    this.messageTypeOpt = this.$dictUtils.getDictList('messageType')
                }
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                if(p.messageClassification == -1) {
                    p.messageClassification = ''
                }
                p.messageData = this.messageData ? this.messageData[0] : ''
                p.messageEndData = this.messageData ? this.messageData[1] : ''
                this.$axios(this.api.auth.selectMessageList, p, 'get').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.loading = false
                })
            },
            // 获取总数
            getCount() {
                this.$axios(this.api.auth.getCount, {}, 'post').then(res => {
                    if (res.status) {
                        this.countInfo = this.recover(this.countInfo, res.data)
                    }
                })
            },
            // 查看
            view(row) {
                this.visible = true
                this.infoLoading = true
                this.getInfo(row.id)
            },
            // 获取详情
            getInfo(id) {
                this.$axios(this.api.auth.messageGetById + "/" + id).then(data => {
                    if (data.status) {
                        this.info = data.data
                        this.infoLoading = false
                    }
                })
            },
            handleClose() {
                this.visible = false
                this.search()
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if(!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.auth.messageRemoveById, ids, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search(0)
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            // 重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.searchForm.resetFields();
                this.messageData = []
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
        margin-right: 8px;
        vertical-align: inherit;
    }
</style>
